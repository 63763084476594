import React, { useEffect, useState } from 'react';
import fetchSubsquid from '../utils/subsquid';
import { BALANCES } from '../utils/queries';
import { encodeAddress } from '@polkadot/util-crypto';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Tooltip, Progress } from 'antd';
import { useAdressContext } from '../context/AddressContext';
import { useApiContext } from '../context/ApiContext';
import SelectAddressFromWallet from './SelectAddressFromWallet';
import { CURRENT_NET } from '../constants';
import TokenLogo from './TokenLogo';
import TokenVerified from './TokenVerified';

export const NavigationContext = React.createContext();

const Balance = () => {
  const { ss58Format } = useApiContext();
  const { address } = useAdressContext();

  const [balances, setBalances] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: 'Token',
      dataIndex: 'token',
      render: (_, { token }) => (
        <div className='col-span-2 flex items-center gap-x-1 justify-center'>
          <TokenLogo logo={token?.logo} />
          <span className='font-semibold md:font-extrabold text-base italic'>{token.ticker}</span>
          <span className='flex items-center justify-center text-sm font-medium bg-pink text-darkBlue rounded-2xl px-2 whitespace-nowrap'>
            {token.standard}
          </span>
          <TokenVerified ticker={token?.ticker} />
        </div>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'timestamp',
      render: (_, { token: { timestamp } }) =>(
        <div className='whitespace-nowrap'>
          {dayjs(timestamp).format('MMM D, YYYY h:mm A')}
        </div>
      ),
      // width: '20%',
    },
    {
      title: 'Progress',
      dataIndex: 'tokens_(minted/total)',
      render: (_, { token }) =>
        {
      const percent = ((Number(token.circulatingSupply || 0) / Number(token.totalSupply || 1)) * 100).toFixed(1);
      return (
        <Tooltip
          title={
            <span>
              {token.circulatingSupply || 0} / {token.totalSupply || 0}
            </span>
          }
        >
          <div className='flex flex-col items-center max-w-[100px] whitespace-nowrap'>
            <span className='text-xs'>{percent}%</span>
            <Progress
              percent={percent}
              strokeColor='#FF87EE'
              showInfo={false}
            />
          </div>
        </Tooltip>
      );
    },
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      render: (balance) => <span>{balance}</span>,
    },

    {
      title: 'Transfer',
      dataIndex: 'transfer',
      render: (_, { token: { ticker } }) => (
        <Link
          id='mint'
          to={`/transfer-token?tick=${ticker}`}
          className='underline cursor-pointer'>
          Transfer
        </Link>
      ),
    },
    {
      title: 'List Token',
      dataIndex: 'list',
      render: (_, { token: { ticker } }) => (
        <Link
          id='mint'
          to={`/list-token?tick=${ticker}`}
          className='underline cursor-pointer'>
          List
        </Link>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await fetchSubsquid({
          query: BALANCES,
          variables: {
            address: encodeAddress(address, ss58Format),
            standard_eq: CURRENT_NET.standard,
          },
        });
        if (res?.data?.userTokenBalances) {
          setBalances(res.data.userTokenBalances);
        }
      } catch (error) {}
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ss58Format, address]);

  return (
    <>
      {address ? (
        <div className='flex flex-col gap-y-5'>
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={balances}
            loading={loading}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  if (e.target.id !== 'mint') {
                    navigate(`/tokens/${record.token.ticker}`);
                  }
                },
              };
            }}
          />
        </div>
      ) : (
        <div className='min-h-[500px] flex flex-column items-center justify-center'>
          <SelectAddressFromWallet />
        </div>
      )}
    </>
  );
};

export default Balance;
