import React, { useState } from 'react';
import { web3FromSource } from '@polkadot/extension-dapp';
import { useApiContext } from '../context/ApiContext';
import { useAdressContext } from '../context/AddressContext';
import { Form, Button } from 'antd';
import queueNotification from '../utils/queueNotification';
import SuccessModal from './SuccessModal';

const Buffer = require('buffer/').Buffer;

const UploadOrdinal = () => {
  const { api, network } = useApiContext();
  const { address, accounts } = useAdressContext();

  const [file, setFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [blockHash, setBlockHash] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    setIsModalOpen(false);

    if (!address) {
      queueNotification({
        header: 'Error',
        message: 'Please connect wallet',
        status: 'error',
      });
      return;
    }

    if (!file) {
      queueNotification({
        header: 'Error',
        message: 'Please select a file',
        status: 'error',
      });
      return;
    }

    try {
      const injector = await web3FromSource(
        accounts.find((acc) => acc.address === address)?.meta?.source
      );
      const signer = injector.signer;

      const buffer = await file.arrayBuffer();

      const payload = {
        source: 'dotordinals',
        name: file.name,
        size: file.size,
        type: file.type,
        data: Buffer.from(buffer).toString('hex'),
      };

      const tx = api.tx.system.remarkWithEvent(JSON.stringify(payload));

      tx.signAndSend(address, { signer }, ({ status, txHash }) => {
        if (status.isInBlock) {
          console.log(`Completed at block hash #${txHash}`);
          console.log(`tx: https://${network}.subscan.io/extrinsic/${txHash}`);

          setBlockHash(txHash);
          setIsModalOpen(true);
        }
      });
    } catch (error) {
      setBlockHash('');
    }
  };

  return (
    <div className='flex items-center justify-center'>
      <SuccessModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        extrinsicHash={blockHash}
        title='Transection Successfull!'
      />
      <div className='flex flex-col gap-y-5 w-[500px]'>
        <h2 className='text-xl font-semibold text-primary'>Upload Ordinal</h2>
        <Form onFinish={handleSubmit} className='flex flex-col gap-y-5'>
          <div className='flex flex-col'>
            <label htmlFor='' className='text-sm font-medium'>
              File
            </label>
            <input type='file' onChange={handleFileChange} />
          </div>
          <div className='flex flex-col'>
            <label>
              By continuing I agree to the{' '}
              <a
                target='_blank'
                href='/disclaimer'
                className='text-blue-500'
                rel='noreferrer'>
                disclaimer{' '}
              </a>
            </label>
            <Button
              htmlType='submit'
              className='text-base font-medium bg-pink h-10 rounded-lg flex items-center justify-center text-primary hover:text-primary focus:text-primary'>
              Upload
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UploadOrdinal;
