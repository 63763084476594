import React, { useEffect, useState } from 'react';
import fetchSubsquid from '../utils/subsquid';
import { PERSONAL_TOKENS } from '../utils/queries';
import { encodeAddress } from '@polkadot/util-crypto';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Pagination, Progress, Tooltip } from 'antd';
import { useAdressContext } from '../context/AddressContext';
import { useApiContext } from '../context/ApiContext';
import SelectAddressFromWallet from './SelectAddressFromWallet';
import { CURRENT_NET } from '../constants';
import TokenLogo from './TokenLogo';
import TokenVerified from './TokenVerified';

export const NavigationContext = React.createContext();

const Personal = () => {
  const { ss58Format } = useApiContext();
  const { address } = useAdressContext();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [tokens, setTokens] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await fetchSubsquid({
          query: PERSONAL_TOKENS,
          variables: {
            address: encodeAddress(address, ss58Format),
            limit: limit,
            offset: (page - 1) * 10,
            standard_eq: CURRENT_NET.standard,
          },
        });
        if (res?.data?.tokens) {
          setTokens(res.data.tokens);
          console.log(res.data, 'counts');
        }
        if (res?.data?.tokensConnection?.totalCount) {
          setTotal(res.data.tokensConnection.totalCount);
        }
      } catch (error) {}
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ss58Format, address, limit]);

  const columns = [
    {
      title: 'Token',
      dataIndex: 'ticker',
      render: (_, token) => (
        <div className='col-span-2 flex items-center gap-x-1 justify-center'>
          <TokenLogo logo={token?.logo} />
          <span className='font-semibold md:font-extrabold text-base italic'>{token.ticker}</span>
          <span className='flex items-center justify-center text-sm font-medium bg-pink text-darkBlue rounded-2xl px-2 whitespace-nowrap'>
            {token.standard}
          </span>
          <TokenVerified ticker={token?.ticker} />
        </div>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'timestamp',
      render: (timestamp) => (
        <div className='whitespace-nowrap'>
          {dayjs(timestamp).format('MMM D, YYYY h:mm A')}
        </div>
      ),
      // width: '20%',
    },
    {
      title: 'Progress',
      dataIndex: 'tokens_(minted/total)',
      render: (_, token) =>
        {
      const percent = ((Number(token.circulatingSupply || 0) / Number(token.totalSupply || 1)) * 100).toFixed(1);
      return (
        <Tooltip
          title={
            <span>
              {token.circulatingSupply || 0} / {token.totalSupply || 0}
            </span>
          }
        >
          <div className='flex flex-col items-center max-w-[100px] whitespace-nowrap'>
            <span className='text-xs'>{percent}%</span>
            <Progress
              percent={percent}
              strokeColor='#FF87EE'
              showInfo={false}
            />
          </div>
        </Tooltip>
      );
    },
    },
    {
      title: 'Limit',
      dataIndex: 'limit',
      render: (limit) => <span>{limit}</span>,
    },
    {
      title: 'Holders',
      dataIndex: 'holders',
      render: (limit) => <span>{limit}</span>,
    },
    {
      title: 'Transactions',
      dataIndex: 'transactions',
      render: (limit) => <span>{limit}</span>,
    },
    {
      title: 'Mint',
      dataIndex: 'mint',
      render: (_, token) => (
        <Link
          id='mint'
          to={`/mint-token?tick=${token.ticker}`}
          className='underline cursor-pointer'>
          Mint
        </Link>
      ),
    },
  ];

  return (
    <>
      {address ? (
        <div className='flex flex-col gap-y-5'>
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={tokens}
            loading={loading}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  if (e.target.id !== 'mint') {
                    navigate(`/tokens/${record.ticker}`);
                  }
                },
              };
            }}
          />
          <div className='flex items-center justify-end'>
            <Pagination
              total={total}
              showTotal={(total) => `Total ${total} tokens`}
              defaultPageSize={limit}
              defaultCurrent={1}
              onChange={(page) => setPage(page)}
              onShowSizeChange={(current, limit) => setLimit(limit)}
            />
          </div>
        </div>
      ) : (
        <div className='min-h-[500px] flex flex-column items-center justify-center'>
          <div>
            <SelectAddressFromWallet />
          </div>
        </div>
      )}
    </>
  );
};

export default Personal;
