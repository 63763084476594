import { Button, Divider } from 'antd';
import { WALLET_OPTIONS } from '../constants';
import { useAdressContext } from '../context/AddressContext';
import { useState } from 'react';

const AvailableWallets = (props) => {
  const { className } = props;
  const { connectWallet, setAddress } = useAdressContext();
  const [wallet, setWallet] = useState('');

  const handleWalletCLick = (wallet) => {
    connectWallet(wallet);
    setWallet(wallet);
    if (props.setWallet) {
      props.setWallet(wallet);
    }
    setAddress('');
  };

  return (
    <div>
      <span className='w-full flex justify-center items-center mt-6'>
        <Button
          onClick={() => handleWalletCLick(WALLET_OPTIONS.SUBWALLET)}
          className={`border-[1px] h-10 px-6 ${
            wallet === WALLET_OPTIONS.SUBWALLET && 'border-[#3B82F6]'
          }`}>
          <img src='/assets/subwallet-icon.png' className='w-5 h-6' alt='' />
        </Button>
      </span>
      <Divider type='horizontal' className='my-2' />
      <div className={`${className}`}>
        <Button
          onClick={() => handleWalletCLick(WALLET_OPTIONS.POLKADOT)}
          className={`border-[1px] h-10 px-6 ${
            wallet === WALLET_OPTIONS.POLKADOT && 'border-[#3B82F6]'
          }`}>
          <img src='/assets/polkadotjs-icon.svg' className='w-5 h-5' alt='' />
        </Button>
        <Button
          onClick={() => handleWalletCLick(WALLET_OPTIONS.TALISMAN)}
          className={`border-[1px] h-10 px-6 ${
            wallet === WALLET_OPTIONS.TALISMAN && 'border-[#3B82F6]'
          }`}>
          <img src='/assets/talisman-icon.svg' className='w-6 h-6' alt='' />
        </Button>
        <Button
          onClick={() => handleWalletCLick(WALLET_OPTIONS.POLYWALLET)}
          className={`border-[1px] h-10 px-6 ${
            wallet === WALLET_OPTIONS.POLYWALLET && 'border-[#3B82F6]'
          }`}>
          <img src='/assets/poly-wallet.svg' className='w-6 h-6' alt='' />
        </Button>
      </div>
    </div>
  );
};
export default AvailableWallets;
