import React from 'react';
import { Routes, Route } from 'react-router-dom';
import UploadOrdinal from '..//UploadOrdinal';
import ViewOrdinal from '..//ViewOrdinal';
import CreateDRC from '..//CreateDRC';
import MintDRC from '..//MintDRC';
import TransferDRC from '..//TransferDRC';
import Personal from '..//Personal';
import NotFound from '..//NotFound';
import Token from '../Token';
import Balance from '../Balance';
import Marketplace from '../Marketplace';
import Tokens from '../Tokens';
import ListDRC from '../ListDRC';
import MarketplaceToken from '../Marketplace/MarketplaceToken';
import Disclaimer from '../Disclaimer';
import AddressBalance from '../AddressBalance';
import Deposit from '../Deposit';
import P2P from '../P2P';

const CustomContent = () => {
  return (
    <div className='p-6 flex-1'>
      <div className='bg-body p-4 rounded-xl border border-solid border-gray-600'>
        <Routes>
          <Route path='/' element={<Tokens />} />
          <Route path='/tokens/:ticker' element={<Token />} />
          <Route path='/create-token' element={<CreateDRC />} />
          <Route path='/mint-token' element={<MintDRC />} />
          <Route path='/transfer-token' element={<TransferDRC />} />
          <Route path='/personal' element={<Personal />} />
          <Route path='/balance' element={<Balance />} />
          <Route path='/view-ordinal' element={<ViewOrdinal />} />
          <Route path='/upload-ordinal' element={<UploadOrdinal />} />
          <Route path='/marketplace' element={<Marketplace />} />
          <Route path='/deposit' element={<Deposit />} />
          <Route
            path='/marketplace/token/:ticker'
            element={<MarketplaceToken />}
          />
          <Route path='/list-token' element={<ListDRC />} />
          <Route path='/disclaimer' element={<Disclaimer />} />
          <Route path='/p2p' element={<P2P />} />
          <Route path='/address-balance' element={<AddressBalance />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </div>
    </div>
  );
};

export default CustomContent;
