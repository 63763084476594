/* eslint-disable */
import React, { useCallback, useState } from 'react';
import fetchSubsquid from '../../utils/subsquid';
import { Table, Pagination } from 'antd';
import { FETCH_TOKEN_ACTIVITIES } from '../../utils/queries';
import dayjs from 'dayjs';
import Address from '../Address';
import { CURRENT_NET } from '../../constants';
import TokenLogo from '../TokenLogo';
import { useParams } from 'react-router-dom';
import TokenVerified from '../TokenVerified';
import { isUnderMaintenance } from '.';
import UnderMaintenance from './UnderMaintenance';

const columns = [
  {
    title: <span className='flex items-center justify-center'>No.</span>,
    dataIndex: 'extrinsicIndex',
    render: (extrinsicIndex, activity) => (
      <div className='flex items-center justify-center text-sm font-medium whitespace-nowrap'>
        #{extrinsicIndex}
      </div>
    ),
  },
  {
    title: <span className='flex items-center justify-center'>Event</span>,
    dataIndex: 'activityType',
    render: (activityType) => (
      <span className='flex items-center justify-center'>{activityType}</span>
    ),
  },
  {
    title: <span className='flex items-center justify-center'>Tick</span>,
    dataIndex: 'ticker',
    render: (ticker, activity) => (
      <div className='flex items-center justify-center gap-x-2'>
        <TokenLogo logo={activity?.token?.logo} />
        <span className='flex items-center justify-center'>
          {activity?.token?.ticker}
        </span>
        <TokenVerified ticker={token?.ticker} />
      </div>
    ),
  },
  {
    title: <span className='flex items-center justify-center'>Price</span>,
    dataIndex: 'price',
    render: (_, activity) => <Price activity={activity} />,
  },
  {
    title: <span className='flex items-center justify-center'>Amount</span>,
    dataIndex: 'amount',
    // sorter: true,
    render: (_, activity) => <Amount activity={activity} />,
  },
  {
    title: <span className='flex items-center justify-center'>Total</span>,
    dataIndex: 'total',
    render: (_, activity) => <Total activity={activity} />,
  },
  {
    title: <span className='flex items-center justify-center'>From</span>,
    dataIndex: 'from',
    render: (from, activity) => <FromAddress activity={activity} />,
  },
  {
    title: <span className='flex items-center justify-center'>To</span>,
    dataIndex: 'to',
    render: (to, activity) => <ToAddress activity={activity} />,
  },
  {
    title: <span className='flex items-center justify-center whitespace-nowrap'>Time</span>,
    dataIndex: 'timestamp',
    render: (timestamp) => (
      <span className='flex items-center justify-center whitespace-nowrap'>
        {dayjs(timestamp).format('MMM D, YYYY h:mm A')}
      </span>
    ),
  },
];

export const Price = ({ activity }) => {
  let price = '';

  if (activity?.activityType === 'List') {
    price = activity?.listing?.amountPerMint;
  } else if (activity?.activityType === 'Mint') {
  } else if (activity?.activityType === 'Transfer') {
    price = activity?.transfer?.amountPerMint;
  }

  if (!price) {
    return <span className='flex items-center justify-center'>--</span>;
  }

  return (
    <span className='flex items-center justify-center'>
      {Number(price).toFixed(2)}
    </span>
  );
};

export const Amount = ({ activity }) => {
  let amount = '';

  if (activity?.activityType === 'List') {
    amount = activity?.listing?.amount;
  } else if (activity?.activityType === 'Mint') {
    amount = activity?.mint?.balance;
  } else if (activity?.activityType === 'Transfer') {
    amount = activity?.transfer?.amount;
  }

  if (!amount) {
    return <span className='flex items-center justify-center'>--</span>;
  }

  return <span className='flex items-center justify-center'>{amount}</span>;
};

export const Total = ({ activity }) => {
  let total = '';

  if (activity?.activityType === 'List') {
    total = activity?.listing?.value;
  } else if (activity?.activityType === 'Mint') {
  } else if (activity?.activityType === 'Transfer') {
    total = activity?.transfer?.value;
  }

  if (!total) {
    return <span className='flex items-center justify-center'>--</span>;
  }

  return <span className='flex items-center justify-center'>{total}</span>;
};

export const ToAddress = ({ activity }) => {
  let address = '';

  if (activity?.activityType === 'List') {
    address = activity?.listing?.from?.address;
  } else if (activity?.activityType === 'Mint') {
    address = activity?.mint?.account?.address;
  } else if (activity?.activityType === 'Transfer') {
    address = activity?.transfer?.to?.address;
  }

  if (!address) {
    return <span className='flex items-center justify-center'>--</span>;
  }

  return (
    <Address
      identiconSize={16}
      addressClassName='text-sm'
      address={address}
      addressMaxLength={10}
    />
  );
};

export const FromAddress = ({ activity }) => {
  let address = '';

  if (activity?.activityType === 'List') {
    address = activity?.listing?.from?.address;
  } else if (activity?.activityType === 'Mint') {
    address = activity?.mint?.account?.address;
  } else if (activity?.activityType === 'Transfer') {
    address = activity?.transfer?.from?.address;
  }

  if (!address) {
    return <span className='flex items-center justify-center'>--</span>;
  }

  return (
    <Address
      identiconSize={16}
      addressClassName='text-sm'
      address={address}
      addressMaxLength={10}
    />
  );
};

const Activities = () => {
  const params = useParams();
  const [activities, setActivities] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sortBy] = useState('timestamp_ASC');
  const [limit, setLimit] = useState(10);

  const getActivities = useCallback(async (page, sortBy, limit) => {
    setLoading(true);
    try {
      const res = await fetchSubsquid({
        query: FETCH_TOKEN_ACTIVITIES,
        variables: {
          limit: limit,
          offset: (page - 1) * limit,
          orderBy: sortBy,
          standard_eq: CURRENT_NET.standard,
          ticker_eq: params?.ticker,
        },
      });
      if (res?.data?.activities) {
        setActivities(res.data.activities);
      }
      if (res?.data?.activitiesConnection?.totalCount) {
        setTotal(res.data.activitiesConnection.totalCount);
      }
    } catch (error) {}
    setLoading(false);
  }, [params?.ticker]);


  if (isUnderMaintenance) {
    return <UnderMaintenance />
  }

  // useEffect(() => {
  //   getActivities(page, sortBy, limit).then(() => {});
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [page, sortBy, limit]);

  return (
    <div className='flex flex-col gap-y-5'>
      {/* <Table
            scroll={{ x: true }}
        columns={columns}
        dataSource={activities}
        loading={loading}
        pagination={false}
      /> */}
      {/* {total > 10 ? (
        <div className='flex items-center justify-end'>
          <Pagination
            total={total}
            showTotal={(total) => `Total ${total} activities`}
            defaultPageSize={limit}
            defaultCurrent={1}
            onChange={(page) => setPage(page)}
            onShowSizeChange={(current, limit) => setLimit(limit)}
          />
        </div>
      ) : null} */}
      <h1 className='text-2xl text-center'>Under Maintenance</h1>
    </div>
  );
};

export default Activities;
