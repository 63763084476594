import React, { useEffect, useState } from 'react';
import fetchSubsquid from '../utils/subsquid';
import { BALANCES } from '../utils/queries';
import { encodeAddress } from '@polkadot/util-crypto';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Tooltip, Progress, Input, Image, Button } from 'antd';
import { useApiContext } from '../context/ApiContext';
import { CURRENT_NET } from '../constants';
import TokenLogo from './TokenLogo';
import TokenVerified from './TokenVerified';

export const NavigationContext = React.createContext();

const AddressBalance = () => {
  const { ss58Format } = useApiContext();

  const [balances, setBalances] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [addressInput, setAddressInput] = useState('');
  const [address, setAddress] = useState('');

  const columns = [
    {
      title: 'Token',
      dataIndex: 'token',
      render: (_, { token }) => (
        <div className='col-span-2 flex items-center gap-x-1 justify-center'>
          <TokenLogo logo={token?.logo} />
          <span className='font-semibold md:font-extrabold text-base italic'>
            {token.ticker}
          </span>
          <span className='flex items-center justify-center text-sm font-medium bg-pink text-darkBlue rounded-2xl px-2 whitespace-nowrap'>
            {token.standard}
          </span>
          <TokenVerified ticker={token?.ticker} />
        </div>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'timestamp',
      render: (_, { token: { timestamp } }) => (
        <div className='whitespace-nowrap'>
          {dayjs(timestamp).format('MMM D, YYYY h:mm A')}
        </div>
      ),
      // width: '20%',
    },
    {
      title: 'Progress',
      dataIndex: 'tokens_(minted/total)',
      render: (_, { token }) => {
        const percent = (
          (Number(token.circulatingSupply || 0) /
            Number(token.totalSupply || 1)) *
          100
        ).toFixed(1);
        return (
          <Tooltip
            title={
              <span>
                {token.circulatingSupply || 0} / {token.totalSupply || 0}
              </span>
            }>
            <div className='flex flex-col items-center max-w-[100px] whitespace-nowrap'>
              <span className='text-xs'>{percent}%</span>
              <Progress
                percent={percent}
                strokeColor='#FF87EE'
                showInfo={false}
              />
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      render: (balance) => <span>{balance}</span>,
    },

    {
      title: 'Transfer',
      dataIndex: 'transfer',
      render: (_, { token: { ticker } }) => (
        <Link
          id='mint'
          to={`/transfer-token?tick=${ticker}`}
          className='underline cursor-pointer'>
          Transfer
        </Link>
      ),
    },
    {
      title: 'List Token',
      dataIndex: 'list',
      render: (_, { token: { ticker } }) => (
        <Link
          id='mint'
          to={`/list-token?tick=${ticker}`}
          className='underline cursor-pointer'>
          List
        </Link>
      ),
    },
  ];

  useEffect(() => {
    if (!address) return;
    (async () => {
      setLoading(true);
      try {
        const res = await fetchSubsquid({
          query: BALANCES,
          variables: {
            address: encodeAddress(address, ss58Format),
            limit: 1,
            offset: 0,
            standard_eq: CURRENT_NET.standard,
          },
        });
        if (res?.data?.userTokenBalances) {
          setBalances(res.data.userTokenBalances);
        }
      } catch (error) {
        console.log(error);
        setBalances([]);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ss58Format, address]);

  return (
    <>
      <label className='text-2xl text-pink flex items-center justify-center '>
        Check out {CURRENT_NET.standard} balance of the address{' '}
      </label>
      <Input
        className='h-10 mt-6'
        onChange={(e) => setAddressInput(e.target.value)}
        value={addressInput}
        placeholder='Please enter your address'
        size='large'
        onPressEnter={() => setAddress(addressInput)}
        addonAfter={
          <Button
            onClick={(e) => {
              setAddress(addressInput);
            }}
            className='border-none shadow-none h-[28px]'>
            <Image
              src='/assets/search.png'
              preview={false}
              alt=''
              width={20}
              height={20}
            />
          </Button>
        }
      />
      {address && (
        <div className='flex flex-col gap-y-5 mt-6'>
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={balances}
            loading={loading}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  if (e.target.id !== 'mint') {
                    navigate(`/tokens/${record.token.ticker}`);
                  }
                },
              };
            }}
          />
        </div>
      )}
    </>
  );
};

export default AddressBalance;
