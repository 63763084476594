import React, { useCallback, useEffect, useState } from 'react';
import fetchSubsquid from '../../utils/subsquid';
import { Table, Pagination, Button } from 'antd';
import { FETCH_TOKEN_LISTING_BY_ADDRESS } from '../../utils/queries';
import dayjs from 'dayjs';
import { CURRENT_NET } from '../../constants';
import TokenLogo from '../TokenLogo';
import { useParams } from 'react-router-dom';
import { useAdressContext } from '../../context/AddressContext';
import Address from '../Address';
import executeTx from '../../utils/executeTx';
import queueNotification from '../../utils/queueNotification';
import { web3FromSource } from '@polkadot/extension-dapp';
import { encodeAddress } from '@polkadot/util-crypto';
import { useApiContext } from '../../context/ApiContext';
import SuccessModal from '../SuccessModal';
import TokenVerified from '../TokenVerified';
import { isUnderMaintenance } from '.';
import UnderMaintenance from './UnderMaintenance';

const MyOrders = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [blockHash, setBlockHash] = useState('');
    const params = useParams();
    const { api, ss58Format, network } = useApiContext();
    const { address, accounts } = useAdressContext();
    const [listings, setListings] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [sortBy] = useState('timestamp_ASC');
    const [limit, setLimit] = useState(10);

    const getListings = useCallback(async (page, sortBy, limit, address) => {
        try {
            const variables = {
                limit: limit,
                offset: (page - 1) * limit,
                orderBy: sortBy,
                standard: CURRENT_NET.standard,
                ticker: params?.ticker,
            };
            if (address) {
                variables.address = address;
            } else {
                return;
            }
            setLoading(true);
            const res = await fetchSubsquid({
            query: FETCH_TOKEN_LISTING_BY_ADDRESS,
            variables: variables,
            });
            if (res?.data?.listings) {
            setListings(res.data.listings);
            }
            if (res?.data?.listingsConnection?.totalCount) {
            setTotal(res.data.listingsConnection.totalCount);
            }
        } catch (error) {}
        setLoading(false);
    }, [params?.ticker]);

    useEffect(() => {
      if (!address) {
        return;
      }
      getListings(page, sortBy, limit, encodeAddress(address, ss58Format)).then(() => {});
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, sortBy, limit, address, ss58Format]);

    const handleCancel = async (list) => {
        const tick = list.token.ticker;

        console.log('selectedAddress', address);
        console.log('tick', tick);

        if (!address) {
          queueNotification({
            header: 'Error',
            message: 'Please connect wallet',
            status: 'error',
          });
          return;
        }

        if (!tick) {
          queueNotification({
            header: 'Error',
            message: 'Token not found',
            status: 'error',
          });
          return;
        }

        try {
          setLoading(true);
          const injector = await web3FromSource(
            accounts.find((acc) => acc.address === address)?.meta?.source
          );
          const signer = injector.signer;

          const payload = {
            p: CURRENT_NET.standard,
            op: 'cancel',
            tick: tick,
            listId: `${list.id}`,
            from: encodeAddress(address, ss58Format),
          };
          const tx = api.tx.system.remarkWithEvent(JSON.stringify(payload));

          await executeTx({
            api: api,
            apiReady: true,
            network: network,
            tx: tx,
            address: address,
            params: { signer },
            onSuccess: async (txHash) => {
              setBlockHash(txHash);
              setIsModalOpen(true);
              queueNotification({
                header: 'Success',
                message: 'Order removed from list successfully',
                status: 'success',
              });
              setLoading(false);
              getListings(page, sortBy, limit, encodeAddress(address, ss58Format)).then(() => {});
            },
            errorMessageFallback: 'Order remove failed.',
            onFailed: async (err) => {
              setLoading(false);
              queueNotification({
                header: 'Error!',
                message: err || 'Something went wrong',
                status: 'error',
              });
            },
          });
        } catch (error) {
          console.error(error);
          setLoading(false);
          queueNotification({
            header: 'Error!',
            message: error?.message || error || 'Something went wrong',
            status: 'error',
          });
        }
      };
    const columns = [
        {
        title: <span className='flex items-center justify-center'>No.</span>,
        dataIndex: 'id',
        render: (id, activity) => (
            <div className='flex items-center justify-center text-sm font-medium whitespace-nowrap'>
            #{id}
            </div>
        ),
        },
        {
        title: <span className='flex items-center justify-center'>Event</span>,
        dataIndex: 'activityType',
        render: (activityType) => (
            <span className='flex items-center justify-center'>List</span>
        ),
        },
        {
        title: <span className='flex items-center justify-center'>Tick</span>,
        dataIndex: 'ticker',
        render: (ticker, activity) => (
            <div className='col-span-2 flex items-center gap-x-1 justify-center'>
              <TokenLogo logo={activity?.token?.logo} />
              <span className='font-semibold md:font-extrabold text-base italic'>{activity?.token.ticker}</span>
              <span className='flex items-center justify-center text-sm font-medium bg-pink text-darkBlue rounded-2xl px-2 whitespace-nowrap'>
                {activity?.token.standard}
              </span>
              <TokenVerified ticker={activity?.token?.ticker} />
            </div>
        ),
        },
        {
        title: <span className='flex items-center justify-center'>Price</span>,
        dataIndex: 'price',
        render: (_, activity) => (
            <span className='flex items-center justify-center'>
            {Number(activity?.amountPerMint).toFixed(2)}
            </span>
        ),
        },
        {
        title: <span className='flex items-center justify-center'>Amount</span>,
        dataIndex: 'amount',
        // sorter: true,
        render: (_, activity) =>  (
            <span className='flex items-center justify-center'>
                {Number(activity?.amount).toFixed(2)}
            </span>
        ),
        },
        {
        title: <span className='flex items-center justify-center'>Total</span>,
        dataIndex: 'total',
        render: (_, activity) =>  (
            <span className='flex items-center justify-center'>
                {Number(activity?.value).toFixed(2)}
            </span>
        ),
        },
        {
        title: <span className='flex items-center justify-center'>From</span>,
        dataIndex: 'from',
        render: (from, activity) => (
            <Address
                identiconSize={16}
                addressClassName='text-sm'
                address={activity?.from?.address}
                addressMaxLength={10}
            />
        ),
        },
        {
        title: <span className='flex items-center justify-center'>To</span>,
        dataIndex: 'to',
        render: (to, activity) => (
            <Address
                identiconSize={16}
                addressClassName='text-sm'
                address={activity?.to?.address}
                addressMaxLength={10}
            />
        ),
        },
        {
        title: <span className='flex items-center justify-center whitespace-nowrap'>Time</span>,
        dataIndex: 'timestamp',
        render: (timestamp) => (
            <span className='flex items-center justify-center whitespace-nowrap'>
            {dayjs(timestamp).format('MMM D, YYYY h:mm A')}
            </span>
        ),
        },
        {
        title: <span className='flex items-center justify-center whitespace-nowrap'>Actions</span>,
        dataIndex: 'cancel',
        render: (_, list) => (
            <Button
                onClick={() => handleCancel(list)}
                className='flex items-center justify-center whitespace-nowrap'
            >
                Cancel
            </Button>
        ),
        },
    ];

    if (isUnderMaintenance) {
      return <UnderMaintenance />
    }
  
    return (
        <div className='flex flex-col gap-y-5'>
        <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={listings}
            loading={loading}
            pagination={false}
        />
        {total > 10 ? (
            <div className='flex items-center justify-end'>
            <Pagination
                total={total}
                showTotal={(total) => `Total ${total} listings`}
                defaultPageSize={limit}
                defaultCurrent={1}
                onChange={(page) => setPage(page)}
                onShowSizeChange={(current, limit) => setLimit(limit)}
            />
            </div>
        ) : null}
        <SuccessModal
            open={isModalOpen}
            setOpen={setIsModalOpen}
            extrinsicHash={blockHash}
            title='Order removed from list successfully'
        />
        </div>
    )
}

export default MyOrders