import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import fetchSubsquid from '../utils/subsquid';
import { FETCH_TOKEN } from '../utils/queries';
import dayjs from 'dayjs';
import { Button, Spin } from 'antd';
import { BlockOutlined, LoadingOutlined } from '@ant-design/icons';
import { useApiContext } from '../context/ApiContext';
import Address from './Address';
import { useNavigate } from 'react-router-dom';
import { Card } from 'antd';
import { CURRENT_NET } from '../constants';
import TokenLogo from './TokenLogo';
import TokenVerified from './TokenVerified';

const Token = () => {
  const params = useParams();
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const { network } = useApiContext();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await fetchSubsquid({
          query: FETCH_TOKEN,
          variables: {
            ticker: params?.ticker,
            standard_eq: CURRENT_NET.standard,
          },
        });
        if (res?.data?.tokens?.length) {
          setToken(res.data.tokens[0]);
        }
      } catch (error) {}
      setLoading(false);
    })();
  }, [params?.ticker]);

  return token ? (
    <section className='flex flex-col gap-y-5'>
      {loading ? (
        <section className='min-h-[300px] flex items-center justify-center'>
          <Spin className='text-5xl' indicator={<LoadingOutlined />} />
        </section>
      ) : (
        <div className='grid grid-cols-12 gap-5 text-primary'>
          <div className='col-span-12 md:col-span-8 flex flex-col gap-y-3'>
            <div className='flex items-center gap-2'>
              <TokenLogo logo={token?.logo} />
              <span className='font-extrabold text-base italic'>
                {token.ticker}
              </span>
              <span className='flex items-center justify-center text-sm font-medium bg-pink text-darkBlue rounded-2xl px-2 whitespace-nowrap'>
                {token.standard}
              </span>
              <TokenVerified ticker={token?.ticker} />
            </div>
            <div className='flex items-center gap-5'>
              <div className='font-semibold text-lg'>Author</div>
              <div className='font-medium text-base'>
                <Button
                  type='link'
                  target='_blank'
                  href={`https://${network}.subscan.io/account/${token?.owner?.address}`}>
                  <Address address={token?.owner?.address} />
                </Button>
              </div>
            </div>
            <div className='flex items-center gap-5'>
              <div className='font-semibold text-lg'>
                Total Supply
              </div>
              <div className='font-medium text-base'>
                {token.totalSupply}
              </div>
            </div>
            <div className='flex items-center gap-5'>
              <div className='font-semibold text-lg'>Minted</div>
              <div className='font-medium text-base'>
                {token.circulatingSupply}
              </div>
            </div>
            <div className='flex items-center gap-5'>
              <div className='font-semibold text-lg'>Holders</div>
              <div className='font-medium text-base'>
                {token.holders}
              </div>
            </div>
            <div className='flex items-center gap-5'>
              <div className='font-semibold text-lg'>Transfers</div>
              <div className='font-medium text-base'>
                {token.transactions}
              </div>
            </div>
            <div className='flex items-center gap-5'>
              <div className='font-semibold text-lg'>
                Blocknumber
              </div>
              <div className='font-medium text-base'>
                <Button
                  type='link'
                  target='_blank'
                  href={`https://${network}.subscan.io/block/${token.blockNumber}`}
                  icon={<BlockOutlined />}>
                  {token.blockNumber}
                </Button>
              </div>
            </div>
            <div className='flex items-center gap-5'>
              <div className='font-semibold text-lg'>Created At</div>
              <div className='font-medium text-base'>
                {dayjs(token?.timestamp).format('MMM D, YYYY h:mm A')}
              </div>
            </div>
            {token.circulatingSupply < token.totalSupply ? (
              <div className='grid grid-cols-10'>
                <div className='col-span-2 font-semibold text-lg'></div>
                <Button
                  className='col-span-2 font-medium text-base'
                  type='default'
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigate(`/mint-token?tick=${token.ticker}`);
                  }}>
                  Mint
                </Button>
              </div>
            ) : null}
          </div>
          <div className='col-span-12 md:col-span-4 flex items-center gap-x-1 justify-center'>
            <Card bordered={true} style={{ width: 400 }}>
              <pre>
                {JSON.stringify(
                  {
                    p: 'pdc-20',
                    op: 'deploy',
                    tick: token.ticker,
                    max: token.totalSupply,
                    lim: token.limit,
                  },
                  null,
                  4
                )}
              </pre>
            </Card>
          </div>
        </div>
      )}
    </section>
  ) : null;
};

export default Token;
