import React from 'react';

const P2P = () => {
  return (
    <div className='text-sm text-white flex flex-col gap-4'>
      <p>
        Since Polkadot does not have any smart contracts, the process of creating a marketplace is extremely complicated and risky. Hence we advise users to trade their ordinals using a P2P multisig based system.
      </p>
      <h1 className='text-xl font-medium'>P2P multisig based trading system for Dot Ordinals</h1>
      <div>In our methodology,<br />
        <p className='ml-2'>a. let's call the <b>Seller</b> who is selling an asset(example - $WOOD) as <b>Sailor</b></p>
        <p className='ml-2'>b. let's call the <b>Buyer</b> who is buying an asset(example - $WOOD) in exchange of DOT as <b>Bob</b></p>
      </div>

      <h1 className='text-xl font-medium'>Process for P2P trade</h1>
      <div className='ml-2'>
        <p>1. Bob and Sailor agree to trade their assets</p>
        <p>2. Bob and Sailor create a multisig wallet - Using polkadot.js, Polkasafe or Multix.</p>
        <p>3. Sailor deposits their asset(ex- $WOOD) into the multisig account</p>
        <p>4. Buyer deposits their DOT in the same multisig account</p>
        <p>5. Once both Bob and Sailor deposit their assets($WOOD & DOT) into the multisig wallet, then can they create a batch transaction to send $WOOD to Bob and DOT to Sailor. </p>
        <p>6. This marks the completion of the P2P trade</p>
      </div>

      <p>
        Using a multisig for a traditional OTC or P2P buy ensures mutual trust and both-party control on the assets.
      </p>

      <p>
        More information on how to use multisig wallet in Polkadot here - <a className='underline text-blue-600' href='https://wiki.polkadot.network/docs/learn-account-multisig' target='_blank' rel="noopener noreferrer">https://wiki.polkadot.network/docs/learn-account-multisig</a>
      </p>
    </div>
  );
};
export default P2P;
