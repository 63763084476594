export const FEE_RECEIVER = '5E58hDFmi6o9VLWca3ZaxcaoFnd2QkyA8b3wuuwk5CJa1NUo';

export const MINT_FEE_RECEIVER = '1pyosGq3KbeRmheeREen2sZxPLkeKBp8K8FgHtNPArKAQb1';

export const chainProperties = {
  polkadot: {
    tokenDecimals: 10,
  },
  kusama: {
    tokenDecimals: 12,
  },
};
export const WALLET_OPTIONS = {
  POLKADOT: 'polkadot-js',
  TALISMAN: 'talisman',
  POLYWALLET: 'polywallet',
  SUBWALLET: 'subwallet-js',
};
export const STANDARDS_INFO = {
  TEST_NET: {
    standard: 'drc-20',
  },
  MAIN_NET: {
    standard: 'pdc-20',
  },
};
export const CURRENT_NET = STANDARDS_INFO.MAIN_NET;
