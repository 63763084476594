import React, { useState } from 'react';
import { web3FromSource } from '@polkadot/extension-dapp';
import { useApiContext } from '../context/ApiContext';
import { useAdressContext } from '../context/AddressContext';
import BN from 'bn.js';
import { Form, Button } from 'antd';
import queueNotification from '../utils/queueNotification';
import SuccessModal from './SuccessModal';
import executeTx from '../utils/executeTx';

const DEPOSIT_ADDRESS = '131RqYWqZt4cvsX8Xgcb6mQx7Qcg74XJD5nS5Cw6dHL6Budr';

const Deposit = () => {
  const { api, network } = useApiContext();
  const { address, accounts } = useAdressContext();

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [blockHash, setBlockHash] = useState('');


  const handleSubmit = async () => {
    if (!address) {
      queueNotification({
        header: 'Error',
        message: 'Please connect wallet',
        status: 'error',
      });
      return;
    }

    const balance = await api.query.system.account(address)
    console.log(balance.data.free.toString());

    if (balance.data.free.lt(new BN('50000000000'))) {
      queueNotification({
        header: 'Error',
        message: 'Insufficient balance. Please deposit 5 DOT in your address to continue.',
        status: 'error',
      });
      return;
    }

    try {
      setLoading(true);
      const injector = await web3FromSource(
        accounts.find((acc) => acc.address === address)?.meta?.source
      );
      const signer = injector.signer;

      const remarkTx = api.tx.system.remarkWithEvent('dotordinals:deposit');
      const feeTx = api.tx.balances?.transferKeepAlive(
        DEPOSIT_ADDRESS,
        new BN('50000000000')
      );

      const tx = api.tx.utility.batchAll([remarkTx, feeTx]);

      await executeTx({
        api: api,
        apiReady: true,
        network: network,
        tx: tx,
        address: address,
        params: { signer },
        onSuccess: async (txHash) => {
          setIsModalOpen(true);
          setBlockHash(txHash);
          queueNotification({
            header: 'Success',
            message: 'Token minted successfully',
            status: 'success',
          });
          setLoading(false);
        },
        errorMessageFallback: 'Token minting failed.',
        onFailed: async (err) => {
          setLoading(false);
          queueNotification({
            header: 'Error!',
            message: err || 'Something went wrong',
            status: 'error',
          });
        },
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
      queueNotification({
        header: 'Error!',
        message: error?.message || error || 'Something went wrong',
        status: 'error',
      });
    }
  };

  return (
    <div className='flex items-center justify-center'>
      <div className='flex flex-col gap-y-5 w-[500px]'>
        <h2 className='text-xl font-semibold text-white'>Deposit 5 Dot for NFT drop</h2>
        <Form onFinish={handleSubmit} className='flex flex-col gap-y-5'>
          <label>
            By continuing I agree to the{' '}
            <a
              target='_blank'
              href='/disclaimer'
              className='text-blue-500'
              rel='noreferrer'>
              disclaimer{' '}
            </a>
          </label>
          <div className='flex flex-col'>
            <Button
              loading={loading}
              disabled={loading}
              htmlType='submit'
              className='text-base font-medium bg-pink h-10 rounded-lg flex items-center justify-center text-primary hover:text-primary focus:text-primary'>
              Deposit
            </Button>
          </div>
        </Form>
      </div>
      <SuccessModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        extrinsicHash={blockHash}
        title='Deposit success!'
      />
    </div>
  );
};

export default Deposit;
