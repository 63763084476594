import React, { useState, useEffect } from 'react';
import { web3FromSource } from '@polkadot/extension-dapp';
import { useLocation } from 'react-router-dom';
import { useApiContext } from '../context/ApiContext';
import { encodeAddress } from '@polkadot/util-crypto';
import { useAdressContext } from '../context/AddressContext';
import fetchSubsquid from '../utils/subsquid';
import { BALANCES } from '../utils/queries';
import { Input, Form, Button } from 'antd';
import queueNotification from '../utils/queueNotification';
import SuccessModal from './SuccessModal';
import executeTx from '../utils/executeTx';
import { CURRENT_NET } from '../constants';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const TransferDRC = () => {
  const { api, ss58Format, network } = useApiContext();
  const { address, accounts } = useAdressContext();

  const query = useQuery();
  const [tick, setTick] = useState(query.get('tick'));
  const [amount, setAmount] = useState(0);
  const [to, setTo] = useState('');
  const [balances, setBalances] = useState([]);
  const [loading, setLoading] = useState(false);
  const limitForSlectedTick =
    balances?.find((balance) => balance?.token.ticker === tick)?.balance || 0;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [blockHash, setBlockHash] = useState('');

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await fetchSubsquid({
          query: BALANCES,
          variables: {
            address: encodeAddress(address, ss58Format),
            standard_eq: CURRENT_NET.standard,
          },
        });
        if (res?.data?.userTokenBalances) {
          setBalances(res.data.userTokenBalances);
        }
      } catch (error) {}
      setLoading(false);
    })();
  }, [address, ss58Format]);

  const handleTickChange = (e) => {
    setTick(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(Number(e.target.value));
  };

  const handleToChange = (e) => {
    setTo(e.target.value);
  };

  const handleSubmit = async () => {
    console.log('selectedAddress', address);
    console.log('tick', tick);
    console.log('amount', amount);
    console.log('to', to);

    if (!address) {
      queueNotification({
        header: 'Error',
        message: 'Please connect wallet',
        status: 'error',
      });
      return;
    }

    if (!tick) {
      queueNotification({
        header: 'Error',
        message: 'Please provide token symbol',
        status: 'error',
      });
      return;
    }

    if (!amount || amount <= 0) {
      queueNotification({
        header: 'Error',
        message: 'Please provide amount',
        status: 'error',
      });
      return;
    }

    if (!to) {
      queueNotification({
        header: 'Error',
        message: 'Please provide to address',
        status: 'error',
      });
      return;
    }

    if (encodeAddress(to, ss58Format) === encodeAddress(address, ss58Format)) {
      queueNotification({
        header: 'Error',
        message: 'You cannot send tokens to yourself',
        status: 'error',
      });
      return;
    }

    try {
      setLoading(true);
      const injector = await web3FromSource(
        accounts.find((addr) => addr.address === address)?.meta?.source
      );
      const signer = injector.signer;

      const payload = {
        p: CURRENT_NET.standard,
        op: 'transfer',
        tick: tick,
        to: to,
        amt: `${amount}`,
      };

      const tx = api.tx.system.remarkWithEvent(JSON.stringify(payload));

      await executeTx({
        api: api,
        apiReady: true,
        network: network,
        tx: tx,
        address: address,
        params: { signer },
        onSuccess: async (txHash) => {
          setIsModalOpen(true);
          setBlockHash(txHash);
          queueNotification({
            header: 'Success',
            message: 'Transfer successful',
            status: 'success',
          });
          setLoading(false);
        },
        errorMessageFallback: 'Transfer failed.',
        onFailed: async (err) => {
          setLoading(false);
          queueNotification({
            header: 'Error!',
            message: err || 'Something went wrong',
            status: 'error',
          });
        },
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
      queueNotification({
        header: 'Error!',
        message: error?.message || error || 'Something went wrong',
        status: 'error',
      });
    }
  };

  return (
    <div className='flex items-center justify-center'>
      <div className='flex flex-col gap-y-5 w-[500px]'>
        <h2 className='text-xl font-semibold text-primary'>
          Transfer PDC 20 token
        </h2>
        <Form onFinish={handleSubmit} className='flex flex-col gap-y-5'>
          <div className='flex flex-col'>
            <label htmlFor='' className='text-sm font-medium'>
              Tick
            </label>
            <Input
              name='tick'
              type='text'
              disabled={loading}
              placeholder='Ticker'
              className='rounded-xl m-0 h-10'
              value={tick}
              onChange={handleTickChange}
            />
          </div>
          <div className='flex flex-col'>
            <label htmlFor='' className='text-sm font-medium'>
              To Address
            </label>
            <Input
              name='amount'
              type='text'
              disabled={loading}
              placeholder='Receiver Address'
              className='rounded-xl m-0 h-10'
              onChange={handleToChange}
            />
          </div>
          <div className='flex flex-col'>
            <label htmlFor='' className='text-sm font-medium'>
              Enter Amount
            </label>
            <Input
              name='amount'
              type='number'
              disabled={loading}
              placeholder='amount'
              className='rounded-xl m-0 h-10'
              onChange={handleAmountChange}
            />
            {!!limitForSlectedTick && (
              <span className='font-medium'>Limit: {limitForSlectedTick}</span>
            )}
          </div>
          <label>
            By continuing I agree to the{' '}
            <a
              target='_blank'
              href='/disclaimer'
              className='text-blue-500'
              rel='noreferrer'>
              disclaimer{' '}
            </a>
          </label>
          <div className='flex flex-col'>
            <Button
              htmlType='submit'
              loading={loading}
              disabled={loading}
              className='text-base font-medium bg-pink h-10 rounded-lg flex items-center justify-center text-primary hover:text-primary focus:text-primary'>
              Transfer Token
            </Button>
          </div>
        </Form>
      </div>
      <SuccessModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        extrinsicHash={blockHash}
        title='Transfer successfull!'
      />
    </div>
  );
};

export default TransferDRC;
