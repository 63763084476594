import React, { useCallback, useEffect, useState } from 'react';
import fetchSubsquid from '../utils/subsquid';
import { GET_TOKENS } from '../utils/queries';
import dayjs from 'dayjs';
import { Table, Pagination, Progress, Tooltip, Input } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { CURRENT_NET } from '../constants';
import TokenLogo from './TokenLogo';
import TokenVerified from './TokenVerified';

const { Search } = Input;

const columns = [
  {
    title: 'Token',
    dataIndex: 'ticker',
    render: (_, token) => {
      return (
        <div className='col-span-2 flex items-center gap-x-1 justify-center'>
          <TokenLogo logo={token?.logo} />
          <span className='font-semibold md:font-extrabold text-base italic'>{token.ticker}</span>
          <span className='flex items-center justify-center text-sm font-medium bg-pink text-darkBlue rounded-2xl px-2 whitespace-nowrap'>
            {token.standard}
          </span>
          <TokenVerified ticker={token?.ticker} />
        </div>
      );
    },
  },
  {
    title: 'Created At',
    dataIndex: 'timestamp',
    sorter: true,
    render: (timestamp) => (
      <div className='whitespace-nowrap'>
        {dayjs(timestamp).format('MMM D, YYYY h:mm A')}
      </div>
    ),
    // width: '20%',
  },
  {
    title: 'Progress',
    dataIndex: 'tokens_(minted/total)',
    render: (_, token) => {
      const percent = (
        (Number(token.circulatingSupply || 0) /
          Number(token.totalSupply || 0)) *
        100
      ).toFixed(1);
      return (
        <Tooltip
            title={
              <span>
                {token.circulatingSupply || 0} / {token.totalSupply || 0}
              </span>
            }
          >
            <div className='flex flex-col items-center whitespace-nowrap'>
              <span className='text-xs'>{percent}%</span>
              <Progress
                percent={percent}
                strokeColor='#FF87EE'
                showInfo={false}
              />
            </div>
          </Tooltip>
      );
    },
  },
  {
    title: 'Limit',
    dataIndex: 'limit',
    render: (limit) => <span>{limit}</span>,
  },
  {
    title: 'Holders',
    dataIndex: 'holders',
    sorter: true,
    render: (limit) => <span>{limit}</span>,
  },
  {
    title: 'Transactions',
    sorter: true,
    dataIndex: 'transactions',
    render: (limit) => <span>{limit}</span>,
  },
  {
    title: 'Mint',
    dataIndex: 'mint',
    render: (_, token) => (
      <Link
        id='mint'
        to={`/mint-token?tick=${token.ticker}`}
        className='underline cursor-pointer'>
        Mint
      </Link>
    ),
  },
];

const Tokens = () => {
  const [tokens, setTokens] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState('timestamp_ASC');
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');

    const getTokens = useCallback(async (page, sortBy, limit, query) => {
        setLoading(true);
        try {
          const variables = {
            limit: limit,
            offset: (page - 1) * limit,
            orderBy: sortBy,
            standard_eq: CURRENT_NET.standard,
          };
          if (query) {
            variables.ticker = query;
          }
          const res = await fetchSubsquid({
            query: GET_TOKENS,
            variables: variables
          });
          if (res?.data?.tokens) {
            setTokens(res.data.tokens);
          }
          if (res?.data?.tokensConnection?.totalCount) {
            setTotal(res.data.tokensConnection.totalCount);
          }
        } catch (error) {
  
        }
        setLoading(false);
    }, []);

  useEffect(() => {
    getTokens(page, sortBy, limit, searchQuery || '').then(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sortBy, limit, searchQuery]);

  useEffect(() => {
    getTokens(1, 'timestamp_ASC', 10).then(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field === 'timestamp') {
      if (sorter.order === 'ascend') {
        setSortBy('timestamp_ASC');
      } else {
        setSortBy('timestamp_DESC');
      }
    }
    if(sorter.field === 'transactions'){
      if (sorter.order === 'ascend') {
        setSortBy('transactions_ASC');
      } else {
        setSortBy('transactions_DESC');
      }}
      if(sorter.field === 'holders'){
      if (sorter.order === 'ascend') {
        setSortBy('holders_ASC');
      } else {
        setSortBy('holders_DESC');
      }}
  };

  return (
    <div className='flex flex-col gap-y-5'>
      <div className='flex items-center justify-between'>
        <h3 className='text-primary font-medium md:font-semibold text-base md:text-xl'>
          The full list of Tokens
        </h3>
        <article>
          <Search
            placeholder="Input Token Name"
            onSearch={(v) => {
              setSearchQuery(v);
            }}
            className='max-w-[150px] md:max-w-[200px]'
          />
        </article>
      </div>
      <div className='flex flex-col gap-y-5'>
        <Table
          columns={columns}
          dataSource={tokens}
          loading={loading}
          pagination={false}
          onChange={handleTableChange}
          scroll={{ x: true }}
          rowClassName='bg-body text-primary hover:bg-body'
          onRow={(record) => {
            return {
              onClick: (e) => {
                if (e.target.id !== 'mint') {
                  navigate(`/tokens/${record.ticker}`);
                }
              },
            };
          }}
        />
        <div className='flex items-center justify-end'>
          <Pagination
            total={total}
            showTotal={(total) => `Total ${total} tokens`}
            defaultPageSize={limit}
            defaultCurrent={1}
            onChange={(page) => setPage(page)}
            onShowSizeChange={(current, limit) => setLimit(limit)}
          />
        </div>
      </div>
    </div>
  );
};

export default Tokens;
