import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { web3FromSource } from '@polkadot/extension-dapp';
import { encodeAddress } from '@polkadot/util-crypto';
import { useApiContext } from '../../context/ApiContext';
import { useAdressContext } from '../../context/AddressContext';
import { FETCH_TOKENS_LISTING } from '../../utils/queries';
import fetchSubsquid from '../../utils/subsquid';
import { Pagination, Card, Spin, Button, Divider, Select, Tooltip, Input } from 'antd';
import Address from '../Address';
import { CURRENT_NET, FEE_RECEIVER } from '../../constants';
import queueNotification from '../../utils/queueNotification';
import SuccessModal from '../SuccessModal';
import executeTx from '../../utils/executeTx';
import BigNumber from 'bignumber.js';
import { customFetch } from '../../utils/services';
import TokenLogo from '../TokenLogo';
import TokenVerified from '../TokenVerified';
import { isUnderMaintenance } from '.';
import UnderMaintenance from './UnderMaintenance';

const { Search } = Input;

const Listed = () => {
  const params = useParams();
  const { api, decimals, ss58Format, network } = useApiContext();
  const { address, accounts } = useAdressContext();

  const [listingLoading, setListingLoading] = useState(false);
  const [buyDisabled, setBuyDisabled] = useState(false);
  const [loading, setLoading] = useState({});
  const [listing, setListing] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [blockHash, setBlockHash] = useState('');
  const [sortBy, setSortBy] = useState('id_ASC');
  const [id, setId] = useState('');

  const checkBuyDisabled = useCallback(async () => {
      try {
        const res = await customFetch('buy-feature-flag');
        if (res.flag) {
          setBuyDisabled(false);
        } else {
          setBuyDisabled(true);
        }
      } catch (error) {
        console.log(error);
      }
  }, [])

  useEffect(() => {
    checkBuyDisabled().then(() => {});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getListing = useCallback(
    async (page, sortBy, limit) => {
      setListingLoading(true);
      try {
        const variables = {
          limit: limit,
          offset: (page - 1) * limit,
          orderBy: sortBy,
          ticker_eq: params?.ticker,
          standard_eq: CURRENT_NET.standard,
        };
        if (id) {
          variables['id'] = id;
        }
        const res = await fetchSubsquid({
          query: FETCH_TOKENS_LISTING,
          variables: variables,
        });
        if (res?.data?.listings) {
          setListing(res.data.listings);
        }
        if (res?.data?.listingsConnection?.totalCount) {
          setTotal(res.data.listingsConnection.totalCount);
        }
        setListingLoading(false);
        return res.data
      } catch (error) {}
      setListingLoading(false);
      return {}
    },
    [params?.ticker, id]
  );

  useEffect(() => {
    getListing(page, sortBy, limit).then(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, sortBy, id]);

  const handleCancel = async (list) => {
    const tick = list.token.ticker;

    console.log('selectedAddress', address);
    console.log('tick', tick);

    if (!address) {
      queueNotification({
        header: 'Error',
        message: 'Please connect wallet',
        status: 'error',
      });
      return;
    }

    if (!tick) {
      queueNotification({
        header: 'Error',
        message: 'Token not found',
        status: 'error',
      });
      return;
    }

    try {
      setLoading(list);
      const injector = await web3FromSource(
        accounts.find((acc) => acc.address === address)?.meta?.source
      );
      const signer = injector.signer;

      const payload = {
        p: CURRENT_NET.standard,
        op: 'cancel',
        tick: tick,
        listId: `${list.id}`,
        from: encodeAddress(address, ss58Format),
      };
      const tx = api.tx.system.remarkWithEvent(JSON.stringify(payload));

      await executeTx({
        api: api,
        apiReady: true,
        network: network,
        tx: tx,
        address: address,
        params: { signer },
        onSuccess: async (txHash) => {
          await checkBuyDisabled();
          setListing((prev) => {
            return prev?.filter((item) => item.id !== list.id);
          });
          await getListing(page, sortBy, limit).then(() => {});
          setBlockHash(txHash);
          setIsModalOpen(true);
          queueNotification({
            header: 'Success',
            message: 'Order removed from list successfully',
            status: 'success',
          });
          setLoading({});
        },
        errorMessageFallback: 'Order remove failed.',
        onFailed: async (err) => {
          setLoading({});
          queueNotification({
            header: 'Error!',
            message: err || 'Something went wrong',
            status: 'error',
          });
        },
      });
    } catch (error) {
      console.error(error);
      setLoading({});
      queueNotification({
        header: 'Error!',
        message: error?.message || error || 'Something went wrong',
        status: 'error',
      });
    }
  };

  const handlePurchase = async (list) => {
    if (buyDisabled) {
      return;
    }
    console.log('list', list);
    const seller = list.from.address;
    const tick = list.token.ticker;
    const amount = Number(list.amount);
    const value = Number(list.value);

    console.log('selectedAddress', address);
    console.log('seller', seller);
    console.log('tick', tick);
    console.log('amount', amount);
    console.log('value', value);

    if (!address) {
      queueNotification({
        header: 'Error',
        message: 'Please connect wallet',
        status: 'error',
      });
      return;
    }

    if (!seller) {
      queueNotification({
        header: 'Error',
        message: 'Seller not found',
        status: 'error',
      });
      return;
    }

    if (
      encodeAddress(seller, ss58Format) === encodeAddress(address, ss58Format)
    ) {
      queueNotification({
        header: 'Error',
        message: 'You cannot buy your own token',
        status: 'error',
      });
      return;
    }

    if (!tick) {
      queueNotification({
        header: 'Error',
        message: 'Token not found',
        status: 'error',
      });
      return;
    }

    if (!amount || amount <= 0) {
      queueNotification({
        header: 'Error',
        message: 'Amount not valid',
        status: 'error',
      });
      return;
    }

    if (!value || value <= 0) {
      queueNotification({
        header: 'Error',
        message: 'Value not valid',
        status: 'error',
      });
      return;
    }

    try {
      setLoading(list);
      const res = await customFetch('create-order', {
        method: 'POST',
        body: JSON.stringify({
            listId: list.id,
            buyerAddress: address,
        })
      });

      const orderId = res?.orderId;

      if (!orderId) {
        queueNotification({
          header: 'Error!',
          message: res.message || 'Something went wrong.',
          status: 'error',
        });
        setLoading({});
        return;
      }

      const injector = await web3FromSource(
        accounts.find((acc) => acc.address === address)?.meta?.source
      );
      const signer = injector.signer;

      const payload = {
        p: CURRENT_NET.standard,
        op: 'purchase',
        tick: tick,
        amt: `${amount}`,
        val: `${value}`,
        orderId: `${orderId}`,
        listId: `${list.id}`,
        from: encodeAddress(address, ss58Format),
      };

      const total = new BigNumber(value).mul(
        new BigNumber(10).pow(new BigNumber(decimals))
      );

      const feeTx = api.tx.balances?.transferKeepAlive(
        encodeAddress(FEE_RECEIVER, ss58Format),
        total.toString()
      );
      const remarkTx = api.tx.system.remarkWithEvent(JSON.stringify(payload));

      const tx = api.tx.utility.batchAll([remarkTx, feeTx]);

      await executeTx({
        api: api,
        apiReady: true,
        network: network,
        tx: tx,
        address: address,
        params: { signer },
        onSuccess: async (txHash) => {
          try {
            await checkBuyDisabled();
            await customFetch('update-order-status', {
              method: 'POST',
              body: JSON.stringify({
                  status: 'success',
                  tick: tick,
                  amount: `${amount}`,
                  value: `${value}`,
                  sellerAddress: seller,
                  buyerAddress: address,
                  orderId,
                  listId: list.id,
                  txHash,
              })
            });
            const data = await getListing(page, sortBy, limit);
            setListing(data?.listings?.filter((newLists) => newLists.id !== list.id));
            setTotal(data?.listingsConnection?.totalCount)
            setBlockHash(txHash);
            setIsModalOpen(true);
            queueNotification({
              header: 'Success',
              message: 'Token purchased successfully',
              status: 'success',
            });
          } catch (error) {
            const data = await getListing(page, sortBy, limit);
            setListing(data?.listings?.filter((newLists) => newLists.id !== list.id));
            setTotal(data?.listingsConnection?.totalCount)
            setBlockHash(txHash);
            setIsModalOpen(true);
            queueNotification({
              header: 'Success',
              message: 'Token purchased successfully',
              status: 'success',
            });
          }
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        },
        errorMessageFallback: 'Token purchase failed.',
        onFailed: async (err, txHash) => {
          try {
            await customFetch('update-order-status', {
              method: 'POST',
              body: JSON.stringify({
                  status: 'failed',
                  tick: tick,
                  amount: `${amount}`,
                  value: `${value}`,
                  sellerAddress: seller,
                  buyerAddress: address,
                  orderId,
                  listId: list.id,
                  txHash,
              })
            });
            queueNotification({
              header: 'Error!',
              message: err || 'Something went wrong',
              status: 'error',
            });
          } catch (error) {
            queueNotification({
              header: 'Error!',
              message: err || 'Something went wrong',
              status: 'error',
            });
          }
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        },
      });
    } catch (error) {
      console.error(error);
      setLoading({});
      queueNotification({
        header: 'Error!',
        message: error?.message || error || 'Something went wrong',
        status: 'error',
      });
    }
  };
  const options = [
    {
      label: 'Amount ASC',
      value: 'amount_ASC',
    },
    {
      label: 'Amount DESC',
      value: 'amount_DESC',
    },
    {
      label: 'Value ASC',
      value: 'value_ASC',
    },
    {
      label: 'Value DESC',
      value: 'value_DESC',
    }
  ]

  if (isUnderMaintenance) {
    return <UnderMaintenance />
  }

  return (
    <Spin spinning={listingLoading} className=''>
      <article className='flex flex-col gap-y-5 min-h-[100px]'>
        <section className='flex items-center justify-between'>
          <h3 className='text-2xl font-semibold'>
            Token Listing
          </h3>
          <article className='flex flex-col md:flex-row items-center gap-5'>
            <Select
              className='text-white flex items-center justify-center rounded-[4px] w-[150px]'
              placeholder='Sort By'
              options={options}
              onChange={(e)=> setSortBy(e)}
            />
            <Search
              placeholder="Input Order ID"
              onSearch={(v) => {
                setId(v);
              }}
              type='number'
              className='max-w-[150px] md:max-w-[200px]'
            />
          </article>
        </section>

        <div className='flex items-center gap-5 flex-wrap'>
          {listing?.map((list, idx) => {
            return (
              <Card
                key={list?.id + idx}
                className='w-full md:w-fit min-w-[200px] cursor-pointer'
                title={
                  <div className='col-span-2 flex items-center gap-x-1'>
                    <TokenLogo logo={list?.token?.logo} />
                    <span className='font-semibold md:font-extrabold text-base italic'>{list?.token?.ticker}</span>
                    <span className='flex items-center justify-center text-sm font-medium bg-pink text-darkBlue rounded-2xl px-2 whitespace-nowrap'>
                      {list?.token?.standard}
                    </span>
                    <TokenVerified ticker={list?.token?.ticker} />
                  </div>
                }
              >
                <div className='flex flex-col gap-y-5'>
                  <div className='flex items-center justify-center text-xl font-semibold'>
                    Amount: {list.amount}
                  </div>
                  <div className='flex items-center justify-between gap-x-2'>
                    <span>#{list.id}</span>
                    {
                      list?.from?.address?
                        <Address
                          identiconSize={16}
                          addressClassName='text-xs'
                          addressMaxLength={10}
                          address={list?.from?.address}
                        />
                      : null
                    }
                  </div>
                  <Divider className='my-0' />
                  <div className='flex items-center justify-between gap-x-2'>
                    <span>Value</span>
                    <span>{list.value} DOT</span>
                  </div>
                  <label>
                    By continuing I agree to the{' '}
                    <a
                      target='_blank'
                      href='/disclaimer'
                      className='text-blue-500'
                      rel='noreferrer'>
                      disclaimer{' '}
                    </a>
                  </label>
                  <div className='flex items-center justify-center gap-x-2'>
                    {
                      buyDisabled?
                        <Tooltip
                          title={
                            <span>
                              Coming Soon
                            </span>
                          }
                        >
                          <Button
                            loading={loading?.id === list?.id}
                            disabled={true}
                            onClick={() => handlePurchase(list)}
                            className='w-full font-medium'
                          >
                            Buy
                          </Button>
                        </Tooltip>
                        : (
                          <Button
                            loading={loading?.id === list?.id}
                            disabled={loading?.id === list?.id}
                            onClick={() => handlePurchase(list)}
                            className='w-full font-medium'
                          >
                            Buy
                          </Button>
                        )
                    }

                    {
                      address?
                       list?.from?.address === encodeAddress(address, ss58Format) ?
                          <Button
                            loading={loading?.id === list?.id}
                            disabled={loading?.id === list?.id}
                            onClick={() => handleCancel(list)}
                            className='w-full font-medium'
                          >
                            Cancel
                          </Button>
                        : null
                      : null
                    }
                  </div>
                </div>
              </Card>
            );
          })}
        </div>
        {total > 10 ? (
          <div className='flex items-center justify-end'>
            <Pagination
              total={total}
              showTotal={(total) => `Total ${total} tokens`}
              defaultPageSize={limit}
              defaultCurrent={1}
              onChange={(page) => setPage(page)}
              onShowSizeChange={(current, limit) => setLimit(limit)}
            />
          </div>
        ) : null}
      </article>
      <SuccessModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        extrinsicHash={blockHash}
        title='Token purchased successfully!'
      />
    </Spin>
  );
};

export default Listed;
