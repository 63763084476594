import React, { useState, useEffect, useRef, useCallback } from 'react';
import { web3FromSource } from '@polkadot/extension-dapp';
import { encodeAddress } from '@polkadot/util-crypto';
import BN from 'bn.js';
import { useApiContext } from '../context/ApiContext';
import { useAdressContext } from '../context/AddressContext';
import { useLocation } from 'react-router-dom';
import fetchSubsquid from '../utils/subsquid';
import { FETCH_TOKENS, TICKER_BALANCES } from '../utils/queries';
import { Input, Form, Button } from 'antd';
import { CURRENT_NET, FEE_RECEIVER, MINT_FEE_RECEIVER } from '../constants';
import queueNotification from '../utils/queueNotification';
import SuccessModal from './SuccessModal';
import executeTx from '../utils/executeTx';
import BigNumber from 'bignumber.js';
import TokenLogo from './TokenLogo';
import TokenVerified from './TokenVerified';
import { isUnderMaintenance } from './Marketplace';
import UnderMaintenance from './Marketplace/UnderMaintenance';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const ListDRC = () => {
  const { api, network, ss58Format } = useApiContext();
  const { address, accounts } = useAdressContext();

  const query = useQuery();
  const [tick, setTick] = useState(query.get('tick'));
  const [amount, setAmount] = useState(0);
  const [value, setValue] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [blockHash, setBlockHash] = useState('');
  const [tokens, setTokens] = useState([]);
  const [userTokenBalance, setUserTokenBalance] = useState({});
  const [loading, setLoading] = useState(false);
  const ref = useRef();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await fetchSubsquid({
          query: FETCH_TOKENS,
          variables: { standard_eq: CURRENT_NET.standard },
        });
        if (res?.data?.tokens) {
          setTokens(res.data.tokens);
        }
      } catch (error) {}
      setLoading(false);
    })();
  }, []);

  const fetchTickerBalance = useCallback(() => {
    clearTimeout(ref.current);
    ref.current = setTimeout(async () => {
      if (!address) {
        return;
      }
      if (!tick) {
        return;
      }
      setLoading(true);
      try {
        const res = await fetchSubsquid({
          query: TICKER_BALANCES,
          variables: {
            address: encodeAddress(address, ss58Format),
            ticker: tick,
          },
        });
        if (res?.data?.userTokenBalances?.length) {
          setUserTokenBalance(res.data.userTokenBalances?.[0]);
        } else {
          setUserTokenBalance(null);
        }
      } catch (error) {}
      setLoading(false);
    }, 1000);
  }, [address, ss58Format, tick]);

  useEffect(() => {
    fetchTickerBalance();
  }, [fetchTickerBalance])

  const handleTickChange = (e) => {
    setTick(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(Number(e.target.value));
  };

  const handleValueChange = (e) => {
    setValue(Number(e.target.value));
  };

  const handleSubmit = async () => {
    setIsModalOpen(false);

    console.log('selectedAddress', address);
    console.log('tick', tick);
    console.log('amount', amount);

    if (!address) {
      queueNotification({
        header: 'Error',
        message: 'Please connect wallet',
        status: 'error',
      });
      return;
    }

    if (!tick) {
      queueNotification({
        header: 'Error',
        message: 'Please provide token symbol',
        status: 'error',
      });
      return;
    }

    if (!amount || amount <= 0) {
      queueNotification({
        header: 'Error',
        message: 'Please provide amount',
        status: 'error',
      });
      return;
    }

    if (!value || value <= 0) {
      queueNotification({
        header: 'Error',
        message: 'Please provide value',
        status: 'error',
      });
      return;
    }

    if (new BigNumber(amount).gt(new BigNumber(userTokenBalance?.balance))) {
      queueNotification({
        header: 'Error',
        message: 'Insufficient balance',
        status: 'error',
      });
      return;
    }

    const token = tokens.find((token) => token.ticker === tick);
    if (!token) {
      queueNotification({
        header: 'Error',
        message: 'Token not found',
        status: 'error',
      });
      return;
    }

    try {
      setLoading(true);
      const injector = await web3FromSource(
        accounts.find((acc) => acc.address === address)?.meta?.source
      );
      const signer = injector.signer;

      const payload = {
        p: CURRENT_NET.standard,
        op: 'transfer',
        subop: 'list',
        tick: tick,
        to: encodeAddress(FEE_RECEIVER, ss58Format),
        amt: `${amount}`,
        value: `${value}`,
      };

      const remarkTx = api.tx.system.remarkWithEvent(JSON.stringify(payload));
      const feeTx = api.tx.balances?.transferKeepAlive(
        MINT_FEE_RECEIVER,
        new BN('1000000000')
      );
      const tx = api.tx.utility.batchAll([remarkTx, feeTx]);

      await executeTx({
        api: api,
        apiReady: true,
        network: network,
        tx: tx,
        address: address,
        params: { signer },
        onSuccess: async (txHash) => {
          setBlockHash(`${txHash}`);
          setIsModalOpen(true);
          queueNotification({
            header: 'Success',
            message: 'Token listed successfully',
            status: 'success',
          });
          setLoading(false);
        },
        errorMessageFallback: 'Token listing failed.',
        onFailed: async (err) => {
          setLoading(false);
          queueNotification({
            header: 'Error!',
            message: err || 'Something went wrong',
            status: 'error',
          });
        },
      });
    } catch (error) {
      setBlockHash('');
      setLoading(false);
      queueNotification({
        header: 'Error!',
        message: error?.message || error || 'Something went wrong',
        status: 'error',
      });
    }
  };

  if (isUnderMaintenance) {
    return <UnderMaintenance />
  }

  return (
    <div className='flex items-center justify-center'>
      <SuccessModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        extrinsicHash={blockHash}
        title='Token listed Successfully'
      />
      <div className='flex flex-col gap-y-5 w-[500px]'>
        <h2 className='text-xl font-semibold text-white'>List PDC 20 token</h2>
        <Form onFinish={handleSubmit} className='flex flex-col gap-y-5'>
          <div className='flex flex-col'>
            <label htmlFor='' className='text-sm font-medium'>
              Enter Token*
            </label>
            <Input
              disabled={loading}
              value={tick}
              className='h-10 w-full rounded-xl'
              onChange={handleTickChange}
            />
          </div>
          <div className='flex flex-col'>
            <label
              htmlFor=''
              className='text-sm font-medium flex items-end justify-between'>
              <span>Enter Amount*</span>{' '}
              <div className='col-span-2 flex items-center gap-x-1 justify-center'>
                <span>
                  {userTokenBalance?.balance && userTokenBalance?.balance}{' '}
                </span>
                <TokenLogo logo={userTokenBalance?.token?.logo} />
                <span className='font-semibold md:font-extrabold text-base italic'>{userTokenBalance?.token?.ticker}</span>
                {/* <span className='flex items-center justify-center text-sm font-medium bg-pink text-darkBlue rounded-2xl px-2 whitespace-nowrap'>
                  {userTokenBalance?.token?.standard}
                </span> */}
                <TokenVerified ticker={userTokenBalance?.token?.ticker} />
              </div>
            </label>
            <Input
              disabled={loading}
              name='amount'
              type='number'
              placeholder='List Amount'
              className='rounded-xl m-0 h-10'
              onChange={handleAmountChange}
            />
          </div>
          <div className='flex flex-col'>
            <label htmlFor='' className='text-sm font-medium'>
              Enter Value* (In DOT)
            </label>
            <Input
              disabled={loading}
              name='value'
              type='number'
              placeholder='value'
              className='rounded-xl m-0 h-10'
              onChange={handleValueChange}
            />
          </div>
          <label>
            By continuing I agree to the{' '}
            <a
              target='_blank'
              href='/disclaimer'
              className='text-blue-500'
              rel='noreferrer'>
              disclaimer{' '}
            </a>
          </label>
          <div className='flex flex-col'>
            <span className='text-sm font-medium'>fee: 0.1</span>
            <Button
              disabled={loading}
              loading={loading}
              htmlType='submit'
              className='text-base font-medium bg-pink h-10 rounded-lg flex items-center justify-center text-primary hover:text-primary focus:text-primary'>
              List Token
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ListDRC;
