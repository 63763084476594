import React, { useContext, useState } from 'react';
import { web3Accounts, web3Enable } from '@polkadot/extension-dapp';
import { Modal } from 'antd';

const APPNAME = 'DOT ordinals';

export const AddressContext = React.createContext({
  address: '',
  accounts: [],
  setAddress: () => {},
  connectWallet: () => {},
});

export function AddressContextProvider(props) {
  const { children = null } = props;
  const [address, setAddress] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
      setIsModalOpen(true);
  };

  const handleCancel = () => {
      setIsModalOpen(false);
  };

  const connectWallet = async (wallet) => {
    try {
      const result = await web3Enable(APPNAME);

      console.log(result,'results');

      const accounts = await web3Accounts({extensions: [wallet]});

      setAccounts(accounts);

      if (accounts.length > 0) {
        setAddress(accounts[0].address);
      } else {
        showModal();
      }

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AddressContext.Provider
      value={{
        address,
        accounts,
        setAddress,
        connectWallet,
      }}
    >
      {children}
      <Modal title="Install Wallet" open={isModalOpen} onCancel={handleCancel} onOk={handleCancel} okType="default">
          <p>Please install polkadot compatible wallet: </p><br/>
          <p>Recommended: <a target='_blank' rel="noreferrer" href="https://polkadot.js.org/extension/">polkadot.js.org/extension/ 🔗</a></p>
          <p>Other: <a target='_blank' rel="noreferrer" href="https://polkadot.network/ecosystem/wallets/">polkadot.network/ecosystem/wallets/ 🔗</a></p><br/>
          <p>After Installation Reload this page</p>
      </Modal>
    </AddressContext.Provider>
    );
  }

  export const useAdressContext = () => {
    return useContext(AddressContext);
  };