import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FETCH_TOKEN } from '../../utils/queries';
import fetchSubsquid from '../../utils/subsquid';
import { Divider, Skeleton, Tabs } from 'antd';
import Listed from './Listed';
import Activities from './Activities';
import { CURRENT_NET } from '../../constants';
import TokenLogo from '../TokenLogo';
import MyOrders from './MyOrders';
import TokenVerified from '../TokenVerified';
import { isUnderMaintenance } from '.';
import UnderMaintenance from './UnderMaintenance';

const MarketplaceToken = () => {
  const params = useParams();
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await fetchSubsquid({
          query: FETCH_TOKEN,
          variables: {
            ticker: params?.ticker,
            standard_eq: CURRENT_NET.standard,
          },
        });
        if (res?.data?.tokens?.length) {
          setToken(res.data.tokens[0]);
        }
      } catch (error) {}
      setLoading(false);
    })();
  }, [params?.ticker]);

  const items = [
    {
      key: 'listed',
      label: 'Listed',
      children: <Listed />,
    },
    {
      key: 'activities',
      label: 'Activities',
      children: <Activities />,
    },
    {
      key: 'my-orders',
      label: 'My Orders',
      children: <MyOrders />,
    },
  ];

  if (isUnderMaintenance) {
    return <UnderMaintenance />
  }

  return (
    <section className='p-5 flex flex-col gap-y-5'>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {token ? (
            <article className='flex flex-col gap-y-2 text-white'>
              <div className='flex items-center gap-x-2'>
                <TokenLogo logo={token?.logo} />
                <span className='font-bold text-2xl'>{token.ticker}</span>
                <span className='flex items-center justify-center text-sm font-medium bg-pink text-darkBlue rounded-2xl px-2'>
                  {token.standard}
                </span>
                <TokenVerified ticker={token?.ticker} />
              </div>
              <div className='flex items-center'>
                <p className='flex items-center gap-x-2'>
                  <span className='text-base text-gray-500'>Floor Price</span>
                  <span>{token?.floor} {' '} DOT</span>
                </p>
                <Divider type='vertical' className='bg-gray-500' />
                <p className='flex items-center gap-x-2'>
                  <span className='text-base text-gray-500'>Volume</span>
                  <span>{token?.totalVolume} {' '} DOT</span>
                </p>
                <Divider type='vertical' className='bg-gray-500' />
                <p className='flex items-center gap-x-2'>
                  <span className='text-base text-gray-500'>Owners</span>
                  <span>{token?.holders}</span>
                </p>
              </div>
            </article>
          ) : null}
        </>
      )}
      <Tabs defaultActiveKey='listed' items={items} />
    </section>
  );
};

export default MarketplaceToken;
