import React, { useContext, useEffect, useState } from 'react';
import { ApiPromise, WsProvider } from '@polkadot/api';
import queueNotification from '../utils/queueNotification';

export const networks = {
    'polkadot': {
        rpcEndpoint: 'wss://node-7144126277301010432.sk.onfinality.io/ws?apikey=c26b705b-b812-4f43-82ea-443d71485156',
        ss58Format: 0,
        decimals: 10,
        rpcEndpoints: [
            {
                label: 'Recommended',
                key: 'wss://node-7144126277301010432.sk.onfinality.io/ws?apikey=c26b705b-b812-4f43-82ea-443d71485156'
            },
			{
				label: 'via On-finality',
				key: 'wss://polkadot.api.onfinality.io/public-ws'
			},
			{
				label: 'via Dwellir',
				key: 'wss://polkadot-rpc.dwellir.com'
			},
			{
				label: 'via Parity',
				key: 'wss://rpc.polkadot.io'
			},
			{
				label: 'via Pinknode',
				key: 'wss://public-rpc.pinknode.io/polkadot'
			},
			{
				label: 'via IBP-GeoDNS1',
				key: 'wss://rpc.ibp.network/polkadot'
			},
			{
				label: 'via IBP-GeoDNS2',
				key: 'wss://rpc.dotters.network/polkadot'
			},
			{
				label: 'via RadiumBlock',
				key: 'wss://polkadot.public.curie.radiumblock.co/ws'
			},
			{
				label: 'via LuckyFriday',
				key: 'wss://rpc-polkadot.luckyfriday.io'
			}
		]
    },
    // 'kusama': {
    //     rpcEndpoint: 'wss://kusama-rpc.polkadot.io',
    //     ss58Format: 2,
    // },
    // 'piccaso': {
    //     rpcEndpoint: 'wss://rpc.composablenodes.tech',
    //     ss58Format: 49,
    // },
};

export const ApiContext = React.createContext({
    api: null,
    ss58Format: 0,
    decimals: 10,
    network: 'polkadot',
    setNetwork: () => {}
});

export function ApiContextProvider(props) {
	const { children = null } = props;
    const [network, setNetwork] = useState('polkadot');
    const [rpcEndpoint, setRPCEndpoint] = useState();
	const [api, setApi] = useState();
    const [apiReady, setApiReady] = useState(false);
    const [ss58Format, setSS58Format] = useState(0);
    const [decimals, setDecimals] = useState(10);

	useEffect(() => {
        setRPCEndpoint(networks[network].rpcEndpoint);
        setSS58Format(networks[network].ss58Format);
        setDecimals(networks[network].decimals);
	}, [network]);

    useEffect(() => {
        (async () => {
            let api = null;
            try {
                setApiReady(false);
                if (!rpcEndpoint) {
                    return;
                }
                const provider = new WsProvider(rpcEndpoint);
                api = await ApiPromise.create({ provider });
                console.log('API created using rpc endpoint ', rpcEndpoint);
                setApi(api);
                api?.on('error', (error) => {
                    console.log('Error from api: ', error);
                    api.disconnect();
                    setApi(null);
                    setRPCEndpoint('');
                    queueNotification({
						header: 'Error!',
						message: 'RPC connection error.',
						status: 'error'
					});
                });
                api?.isReady?.then(() => {
                    setApiReady(true);
                    console.log('API is ready')
                }).catch((error) => {
                    console.log(error);
                    api.disconnect();
                    setApi(null);
                    setRPCEndpoint('');
                    queueNotification({
						header: 'Error!',
						message: 'RPC connection error.',
						status: 'error'
					});
                });
            } catch (error) {
                console.log('Error while creating API: ', error);
                if (api) {
                    api?.disconnect();
                }
                setApi(null);
                setRPCEndpoint('');
                queueNotification({
                    header: 'Error!',
                    message: 'RPC connection error.',
                    status: 'error'
                });
            }
        })();
    }, [rpcEndpoint]);

	return (
        <ApiContext.Provider
            value={{
                api,
                apiReady,
                ss58Format,
                decimals,
                network,
                setNetwork,
                setRPCEndpoint,
                rpcEndpoint
            }}
        >
            {children}
        </ApiContext.Provider>
    );
}

export const useApiContext = () => {
    return useContext(ApiContext);
};