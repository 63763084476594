import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Pagination } from 'antd';
import fetchSubsquid from '../../utils/subsquid';
import { GET_TOKENS_FOR_MARKETPLACE } from '../../utils/queries';
import { CURRENT_NET } from '../../constants';
import TokenLogo from '../TokenLogo';
import TokenVerified from '../TokenVerified';
import UnderMaintenance from './UnderMaintenance';

export const isUnderMaintenance = false;

const columns = [
  {
    title: <span className='flex items-center justify-center'>Tick</span>,
    dataIndex: 'ticker',
    render: (_, token) => (
      <div className='col-span-2 flex items-center gap-x-1 justify-center'>
        <TokenLogo logo={token?.logo} />
        <span className='font-extrabold text-base italic'>{token.ticker}</span>
        <span className='flex items-center justify-center text-sm font-medium bg-pink text-darkBlue rounded-2xl px-2 whitespace-nowrap'>
          {token.standard}
        </span>
        <TokenVerified ticker={token?.ticker} />
      </div>
    ),
  },
  {
    title: (
      <span className='flex items-center justify-center whitespace-nowrap'>Floor Price</span>
    ),
    dataIndex: 'floor',
    sorter: true,
    render: (floor) => (
      <span className='flex items-center justify-center'>{floor} {' '} DOT</span>
    ),
  },
  {
    title: <span className='flex items-center justify-center'>Owners</span>,
    dataIndex: 'holders',
    sorter: true,
    render: (holders) => (
      <span className='flex items-center justify-center'>{holders}</span>
    ),
  },
  {
    title: (
      <span className='flex items-center justify-center whitespace-nowrap'>Total Volume</span>
    ),
    dataIndex: 'totalVolume',
    sorter: true,
    render: (totalVolume) => (
      <span className='flex items-center justify-center'>{Number(totalVolume).toFixed(2)} {' '} DOT</span>
    ),
  },
  {
    title: (
      <span className='flex items-center justify-center whitespace-nowrap'>Total Sales</span>
    ),
    dataIndex: 'totalSales',
    sorter: true,
    render: (totalSales) => (
      <span className='flex items-center justify-center'>{totalSales}</span>
    ),
  },
  {
    title: <span className='flex items-center justify-center whitespace-nowrap'>Market Cap</span>,
    dataIndex: 'marketCap',
    sorter: true,
    render: (marketCap) => (
      <span className='flex items-center justify-center'>{marketCap}</span>
    ),
  },
];

const Marketplace = () => {
  const [tokens, setTokens] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState('timestamp_ASC');
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);

  const getTokens = useCallback(async (page, sortBy, limit) => {
    setLoading(true);
    console.log(sortBy);
    try {
      const res = await fetchSubsquid({
        query: GET_TOKENS_FOR_MARKETPLACE,
        variables: {
          limit: limit,
          offset: (page - 1) * limit,
          orderBy: sortBy,
          standard_eq: CURRENT_NET.standard,
        },
      });
      if (res?.data?.tokens) {
        setTokens(res.data.tokens);
      }
      if (res?.data?.tokensConnection?.totalCount) {
        setTotal(res.data.tokensConnection.totalCount);
      }
    } catch (error) {}
    setLoading(false);
  }, []);

  useEffect(() => {
    getTokens(page, sortBy, limit).then(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sortBy, limit]);
  const handleTableChange = (pagination, filters, sorter) => {
    console.log(sorter)
    if (sorter.field === 'holders') {
      if (sorter.order === 'ascend') {
        setSortBy('holders_ASC');
      } else {
        setSortBy('holders_DESC');
      }
    }
    if(sorter.field === 'totalVolume'){
      if (sorter.order === 'ascend') {
        setSortBy('totalVolume_ASC');
      } else {
        setSortBy('totalVolume_DESC');
      }
    }
    if(sorter.field === 'floor'){
      if (sorter.order === 'ascend') {
        setSortBy('floor_ASC');
      } else {
        setSortBy('floor_DESC');
      }
    }
    if(sorter.field === 'totalSales'){
      if (sorter.order === 'ascend') {
        setSortBy('totalSales_ASC');
      } else {
        setSortBy('totalSales_DESC');
      }
    }
     if(sorter.field === 'marketCap'){
      if (sorter.order === 'ascend') {
        setSortBy('marketCap_ASC');
      } else {
        setSortBy('marketCap_DESC');
      }
    }
  };

  if (isUnderMaintenance) {
    return <UnderMaintenance />
  }

  return (
    <div className='flex flex-col gap-y-5'>
      <Table
        scroll={{ x: true }}
        columns={columns}
        dataSource={tokens}
        loading={loading}
        pagination={false}
        onChange={handleTableChange}
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => {
              if (e.target.id !== 'mint') {
                navigate(`/marketplace/token/${record.ticker}`);
              }
            },
          };
        }}
      />
      <div className='flex items-center justify-end'>
        <Pagination
          total={total}
          showTotal={(total) => `Total ${total} tokens`}
          defaultPageSize={limit}
          defaultCurrent={1}
          onChange={(page) => setPage(page)}
          onShowSizeChange={(current, limit) => setLimit(limit)}
        />
      </div>
    </div>
  );
};

export default Marketplace;
