import React, { useState, useEffect } from 'react';
import { web3FromSource } from '@polkadot/extension-dapp';
import { BN } from '@polkadot/util';
import { useApiContext } from '../context/ApiContext';
import { useAdressContext } from '../context/AddressContext';
import { Input, Form, Button } from 'antd';
import fetchSubsquid from '../utils/subsquid';
import { FETCH_TOKENS } from '../utils/queries';
import queueNotification from '../utils/queueNotification';
import SuccessModal from './SuccessModal';
import executeTx from '../utils/executeTx';
import { CURRENT_NET, MINT_FEE_RECEIVER } from '../constants';

const CreateDRC = () => {
  const { api } = useApiContext();
  const { address, accounts, network } = useAdressContext();

  const [tick, setTick] = useState('');
  const [totalSupply, setTotalSupply] = useState(0);
  const [limit, setLimit] = useState(0);
  const [logo, setLogo] = useState('');
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [blockHash, setBlockHash] = useState('');

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await fetchSubsquid({
          query: FETCH_TOKENS,
          variables: { standard_eq: CURRENT_NET.standard },
        });
        if (res?.data?.tokens) {
          setTokens(res.data.tokens);
        }
      } catch (error) {}
      setLoading(false);
    })();
  }, []);

  const handleTickChange = (e) => {
    setTick(e.target.value);
  };

  const handleLogoChange = (e) => {
    setLogo(e.target.value);
  };

  const handleTotalSupplyChange = (e) => {
    setTotalSupply(e.target.value);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
  };

  const handleSubmit = async () => {
    console.log('address', address);
    console.log('tick', tick);
    console.log('totalSupply', totalSupply);
    console.log('limit', limit);
    console.log('logo', logo);

    if (!address) {
      queueNotification({
        header: 'Error',
        message: 'Please connect wallet',
        status: 'error',
      });
      return;
    }

    if (!tick) {
      queueNotification({
        header: 'Error',
        message: 'Please provide token symbol',
        status: 'error',
      });
      return;
    }

    if (!totalSupply) {
      queueNotification({
        header: 'Error',
        message: 'Please provide total supply',
        status: 'error',
      });
      return;
    }

    if (!limit) {
      queueNotification({
        header: 'Error',
        message: 'Please provide limit',
        status: 'error',
      });
      return;
    }

    if (new BN(totalSupply).lt(new BN(limit))) {
      queueNotification({
        header: 'Error',
        message: 'Total supply must be greater than and equal to limit',
        status: 'error',
      });
      return;
    }

    const token = tokens.find((token) => token.ticker === tick);

    if (token) {
      queueNotification({
        header: 'Error',
        message: 'Token already exists',
        status: 'error',
      });
      return;
    }

    try {
      setLoading(true);
      const injector = await web3FromSource(
        accounts.find((acc) => acc.address === address)?.meta?.source
      );
      const signer = injector.signer;

      const payload = {
        p: CURRENT_NET.standard,
        op: 'deploy',
        tick: tick,
        max: `${totalSupply}`,
        lim: `${limit}`,
      };

      if (logo) {
        payload.logo = logo;
      }

      const remarkTx = api.tx.system.remarkWithEvent(JSON.stringify(payload));
      const feeTx = api.tx.balances?.transferKeepAlive(
        MINT_FEE_RECEIVER,
        new BN('1000000000')
      );
      const tx = api.tx.utility.batchAll([remarkTx, feeTx]);

      await executeTx({
        api: api,
        apiReady: true,
        network: network,
        tx: tx,
        address: address,
        params: { signer },
        onSuccess: async (txHash) => {
          setIsModalOpen(true);
          setBlockHash(txHash);
          queueNotification({
            header: 'Success',
            message:
              'Token created successfully. Check subscan for inscription',
            status: 'success',
          });
          setLoading(false);
        },
        errorMessageFallback: 'Token creation failed.',
        onFailed: async (err) => {
          setLoading(false);
          queueNotification({
            header: 'Error!',
            message: err,
            status: 'error',
          });
        },
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
      queueNotification({
        header: 'Error!',
        message: error?.message || error || 'Something went wrong',
        status: 'error',
      });
    }
  };
  return (
    <div className='flex items-center justify-center'>
      <div className='flex flex-col gap-y-5 w-[500px]'>
        <h2 className='text-xl font-semibold text-white'>
          Create PDC 20 token
        </h2>
        <Form onFinish={handleSubmit} className='flex flex-col gap-y-5'>
          <div className='flex flex-col'>
            <label htmlFor='' className='text-sm font-medium'>
              Tick*
            </label>
            <Form.Item
              name='token_symbol'
              rules={[
                {
                  required: true,
                  message: 'Token Symbol is required.',
                },
              ]}
              className='m-0'>
              <Input
                name='token_symbol'
                type='text'
                maxLength={5}
                placeholder='Token Symbol'
                className='rounded-lg m-0 h-10'
                onChange={handleTickChange}
              />
            </Form.Item>
          </div>
          <div className='flex flex-col'>
            <label htmlFor='' className='text-sm font-medium'>
              Token Logo Image Url (optional)
            </label>
            <Input
              name='tick_logo'
              type='text'
              placeholder='Token Logo Image Url'
              className='rounded-lg m-0 h-10'
              onChange={handleLogoChange}
            />
          </div>
          <div className='flex flex-col'>
            <label htmlFor='' className='text-sm font-medium'>
              Total Supply*
            </label>
            <Form.Item
              name='total_supply'
              rules={[
                {
                  required: true,
                  message: 'Token Total Supply is required.',
                },
              ]}
              className='m-0'>
              <Input
                name='total_supply'
                type='number'
                placeholder='Total Supply'
                className='rounded-lg m-0 h-10'
                onChange={handleTotalSupplyChange}
              />
            </Form.Item>
          </div>
          <div className='flex flex-col'>
            <label htmlFor='' className='text-sm font-medium'>
              Limit*
            </label>
            <Form.Item
              name='limit'
              rules={[
                {
                  required: true,
                  message: 'Limit per Mint is required.',
                },
              ]}
              className='m-0'>
              <Input
                name='limit'
                type='number'
                placeholder='Limit per Mint'
                className='rounded-lg m-0 h-10'
                onChange={handleLimitChange}
              />
            </Form.Item>
          </div>
          <label>
            By continuing I agree to the{' '}
            <a
              target='_blank'
              href='/disclaimer'
              className='text-blue-500'
              rel='noreferrer'>
              disclaimer{' '}
            </a>
          </label>
          <div className='flex flex-col'>
            <span className='text-sm font-medium'>fee: 0.1</span>
            <Button
              loading={loading}
              htmlType='submit'
              className='text-base font-medium bg-pink h-10 rounded-lg flex items-center justify-center text-primary hover:text-primary focus:text-primary'>
              Create Token
            </Button>
          </div>
        </Form>
      </div>
      <SuccessModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        extrinsicHash={blockHash}
        title='Token created successfully. Check subscan for inscription!'
      />
    </div>
  );
};

export default CreateDRC;
