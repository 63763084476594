import React from 'react'
import { Dropdown } from 'antd'
import { networks } from '../../context/ApiContext';
import { DownOutlined } from '@ant-design/icons';

const RPCDropdown = ({ network, setRPCEndpoint, rpcEndpoint }) => {
  return (
    <Dropdown
          menu={{
            defaultSelectedKeys: [rpcEndpoint],
            items: networks?.[network]?.rpcEndpoints?.map((rpc, idx) => ({
              key: rpc.key,
              label: <span className='capitalize'>{rpc.label}</span>,
            })),
            selectable: true,
            onClick: ({ key }) => {
              setRPCEndpoint(key);
            },
          }}
          className='p-0 m-0 flex items-center justify-center'
        >
          <div className='border border-solid text-white border-gray-600 px-3 rounded-lg h-8 md:h-10 font-semibold text-sm md:min-w-[125px] capitalize flex items-center justify-between gap-x-2 cursor-pointer'>
            <p className='flex items-center gap-x-2'>
                <span>
                    <img
                        src="/assets/signal-tower.svg"
                        alt="signal tower"
                        className='h-3 w-3'
                    />
                </span>
                <span className='hidden md:flex'>
                  {
                    rpcEndpoint?
                    networks?.[network]?.rpcEndpoints?.find((rpc) => rpc.key === rpcEndpoint)?.label
                    : 'Select RPC'
                  }
                </span>
            </p>
            <DownOutlined className='hidden md:flex text-xs' />
          </div>
    </Dropdown>
  )
}

export default RPCDropdown