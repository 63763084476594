import { Alert, Button, Modal, Select } from 'antd';
import Address from './Address';
import { useState } from 'react';
import { useAdressContext } from '../context/AddressContext';
import AvailableWallets from './AvailableWallets';

const SelectAddressFromWallet = () => {
  const { accounts, address, setAddress } = useAdressContext();
  const [open, setOpen] = useState(false);
  const accountName = accounts.find((account) => account.address === address)
    ?.meta?.name;
  const options = accounts
    ? accounts?.map((account) => {
        return {
          label: <Address address={account.address} name={account?.meta?.name} />,
          value: account.address,
        };
      })
    : [];

  return (
    <>
      <Button
        type='default'
        htmlType='button'
        onClick={() => setOpen(true)}
        className='flex items-center justify-center h-8 md:h-10 text-white border-gray-600'>
        {!address ? (
          'Connect Wallet'
        ) : (
          <Address identiconSize={18} address={address} name={accountName} />
        )}
      </Button>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={
          <Button
            className='h-10 px-6 mt-4 text-sm tracking-wide'
            onClick={() => setOpen(false)}>
            Confirm
          </Button>
        }>
        <AvailableWallets className='flex w-full justify-center items-center gap-3' />
        {!accounts.length && !address ? (
          <Alert
            message='Select a wallet'
            showIcon
            className='rounded-[4px] mt-4'
          />
        ) : (
          <Select
            className='w-full mt-6 h-10 rounded-[4px]'
            options={options}
            onChange={(address) => setAddress(address)}
            value={address || accounts?.[0].address}
            placeholder='Select Address'
          />
        )}
      </Modal>
    </>
  );
};
export default SelectAddressFromWallet;
